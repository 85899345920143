import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .menu-icon').hasClass('active')){
    $('header .menu-icon').removeClass('active')
    $('header .hamburger').removeClass('active')
    $('header .mob-nav').removeClass('show')
    $('header .mask').fadeOut(200)
  }else{
    $('header .menu-icon').addClass('active')
    $('header .hamburger').addClass('active')
    $('header .mob-nav').addClass('show')
    $('header .mask').fadeIn(200)
  }
})

$('header .mask').on('click',function(){
  $('header .menu-icon').removeClass('active')
  $('header .hamburger').removeClass('active')
  $('header .mob-nav').removeClass('show')
  $('header .mask').fadeOut(200)
})

$('.mob-nav ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('.mob-nav .th-nav a').on('click',function(){
  setTimeout(function(){
    $('header .menu-icon').removeClass('active')
    $('header .hamburger').removeClass('active')
    $('header .mob-nav').removeClass('show')
    $('header .mask').fadeOut(200)
  },500)
})

$('#header-search-open').on('click',function(){
  $('.header-search').addClass('active')
  $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header-search').removeClass('active')
  $('#header-search-input').blur()
})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if(scroH > 200){
    $('.header,.header-search').addClass('scroll')
  }else{
    $('.header,.header-search').removeClass('scroll')
  }
});