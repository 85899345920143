import './index.scss'
$('footer .footer3 .back-top').on('click',function(){
  $("html, body").animate({
    scrollTop: 0
  }, {
      duration: 500,
      easing: "swing"
  });
  return false;
})

$('.fixed-contact .fixed-icon').on('click',function () {
  $('.fixed-contact .fixed-icon').addClass('active')
  $('.fixed-contact .fixed-form').addClass('active')
})

$('.fixed-contact .fixed-form .close-icon').on('click', function () {
  $('.fixed-contact .fixed-icon').removeClass('active')
  $('.fixed-contact .fixed-form').removeClass('active')
})

// 表单点击提示文字上移
$('.fixed-contact .form input').focus(function () {
  if (!$(this).parents('.form-item').hasClass('active')) {
      $(this).parents('.form-item').addClass('active')
  }
}).blur(function () {
  if ($(this).val() == '') {
      $(this).parents('.form-item').removeClass('active')
  }
})