
import '@fancyapps/fancybox/dist/jquery.fancybox.min'
import {WOW} from '../../../node_modules/wowjs/dist/wow';
var wow = new WOW({
  offset: 100
});
wow.init();

//内页左侧二级菜单效果
if($('.ny-sub-nav').length > 0){
  // 点击图标展开三级菜单
    $('.ny-sub-nav ul li.active').find('.sub-nav').slideDown()
    $('.ny-sub-nav ul li:not(.active) .nav-title .iconfont').on('click',function(){
      let $parents = $(this).parents('li')
      console.log($parents)
      if($parents.hasClass('down')){
        $parents.removeClass('down').find('.sub-nav').slideUp()
      }else{
        $('.ny-sub-nav ul li:not(.active)').removeClass('down')
        $('.ny-sub-nav ul li:not(.active)').find('.sub-nav').slideUp()
        $parents.addClass('down').find('.sub-nav').slideDown()
      }
    })

  // 菜单浮动
  $(document).scroll(function () {
    let scroH = $(document).scrollTop();
    let viewH = $(window).height(); //可见高度
    let navTop = $('.ny-position').offset().top - 200
    let navBtm = $('.ny-position').offset().top + $('.ny-position').height() - 200 -$('.ny-sub-nav').height()

    if($(window).width()>= 992){
      if(scroH < navTop){
        $('.ny-sub-nav').removeClass('fixed')
      }else if(scroH >= navTop && scroH <= navBtm){
        $('.ny-sub-nav').addClass('fixed').removeClass('abs')
      }else{
        $('.ny-sub-nav').addClass('abs')
      }
    }
  });
}