module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
;
__p += '\r\n<section class="nybanner">\r\n  <div class="img-cont">\r\n    <b class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n    <div class="text">\r\n      <h1 class="wow transIn" data-wow-duration="1s" data-wow-delay="0.2s">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n      <div class="line"></div>\r\n      <div class="icon"><i class="iconfont icon-jiantou_down"></i></div>\r\n    </div>\r\n  </div>\r\n</section>';

}
return __p
}