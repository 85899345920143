import './index.scss'

$(document).ready(function(){
  if($('.nybanner').length > 0){
    $(document).scroll(function () {
      var scroH = $(document).scrollTop();
    
      $('.nybanner .img-cont .bg-img').css('transform','translateY('+ scroH / 3 +'px)')
    });
    $('.nybanner .img-cont .icon').on('click',function(){
      let h
      if($(window).width() >= 1280){
        h = $('.nybanner .img-cont').offset().top + $('.nybanner .img-cont').outerHeight() - $('.header').height()
      }else{
        h = $('.nybanner .img-cont').offset().top + $('.nybanner .img-cont').outerHeight() - $('.header').height()
      }
      $('html ,body').animate({scrollTop: h},'slow');
    })
  
    if(window.location.href.indexOf('#md') != -1){
      let h
      if($(window).width() >= 1280){
        h = $('.nybanner .img-cont').offset().top + $('.nybanner .img-cont').outerHeight() - $('.header').height()
      }else{
        h = $('.nybanner .img-cont').offset().top + $('.nybanner .img-cont').outerHeight() - $('.header').height()
      }
      $('html ,body').animate({scrollTop: h},0);
    }
  }
})
